import * as React from "react"
import Main from "../components/Layout/Main";
import Breadcrumb from "../components/Layout/Breadcrumb";
import Seo from "../components/Seo";

const PoliticaDePrivacidade = () => {
    return (
        <Main>
            <Seo title="Polĩtica de Privacidade" description="Confira a Política de Privacidade do site Mister Brinque."/>
            <Breadcrumb title="Política de Privacidade"/>
            <div className="container mx-auto p-6 bg-white my-4 text-justify rounded">
                <h1 className="text-2xl">Política de Privacidade do <span className="font-bold">Mister Brinque</span></h1>
                <p className="text-dark-gray text-sm pt-3">Este Aplicativo recolhe alguns Dados Pessoais dos Usuários.</p>
                <p className="text-dark-gray text-sm">Este documento pode ser impresso para fins de consulta, através do comando imprimir nas configurações de seu navegador.</p>

                <h1 className="text-lg mt-8">Proprietário e Controlador de Dados</h1>
                <p className="text-dark-gray text-sm pt-3">Rua Armando Dias, 44 - Centro - Porto Feliz/SP - CEP 18540-159</p>
                <p className="text-dark-gray text-sm"><span className="font-bold">E-mail de contato do Proprietário:</span> contato@misterbrinque.com.br</p>

                <h1 className="text-lg mt-8">Tipos de Dados coletados</h1>
                <p className="text-dark-gray text-sm pt-3">Entre os tipos de Dados Pessoais que este Aplicativo coleta, por si mesmo ou através de terceiros, existem: Rastreador; Dados de uso; número de telefone; e-mail.</p>
                <p className="text-dark-gray text-sm pt-3">Detalhes completos sobre cada tipo de Dados Pessoais coletados são fornecidos nas seções dedicadas desta política de privacidade ou por textos explicativos específicos exibidos antes da coleta de Dados.</p>
                <p className="text-dark-gray text-sm pt-3">A menos que especificado diferentemente todos os Dados solicitados por este Aplicativo são obrigatórios e a falta de fornecimento destes Dados poderá impossibilitar este Aplicativo de fornecer os seus Serviços. Nos casos em que este Aplicativo afirmar especificamente que alguns Dados não forem obrigatórios, os Usuários ficam livres para deixarem de comunicar estes Dados sem nenhuma consequência para a disponibilidade ou o funcionamento do Serviço.</p>
                <p className="text-dark-gray text-sm pt-3">Os Usuários que tiverem dúvidas a respeito de quais Dados Pessoais são obrigatórios estão convidados a entrar em contato com o Proprietário.</p>
                <p className="text-dark-gray text-sm pt-3">Quaisquer usos de cookies – ou de outras ferramentas de rastreamento – por este Aplicativo ou pelos proprietários de serviços terceiros utilizados por este Aplicativo serão para a finalidade de fornecer os Serviços solicitados pelo Usuário, além das demais finalidades descritas no presente documento e na Política de Cookies, se estiver disponível.</p>
                <p className="text-dark-gray text-sm pt-3">Os Usuários ficam responsáveis por quaisquer Dados Pessoais de terceiros que forem obtidos, publicados ou compartilhados através deste Serviço (este Aplicativo) e confirmam que possuem a autorização dos terceiros para fornecerem os Dados para o Proprietário.</p>

                <h1 className="text-lg mt-8">Modo e local de processamento dos Dados</h1>
                <p className="text-sm font-bold pt-3">Método de processamento</p>
                <p className="text-dark-gray text-sm pt-3">O Proprietário tomará as medidas de segurança adequadas para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos Dados.</p>
                <p className="text-dark-gray text-sm pt-3">O processamento dos Dados é realizado utilizando computadores e /ou ferramentas de TI habilitadas, seguindo procedimentos organizacionais e meios estritamente relacionados com os fins indicados. Além do Proprietário, em alguns casos, os Dados podem ser acessados por certos tipos de pessoas encarregadas, envolvidas com a operação deste Serviço (este Aplicativo) (administração, vendas, marketing, administração legal do sistema) ou pessoas externas (como fornecedores terceirizados de serviços técnicos, carteiros, provedores de hospedagem, empresas de TI, agências de comunicação) nomeadas, quando necessário, como Processadores de Dados por parte do Proprietário. A lista atualizada destas partes pode ser solicitada ao Proprietário a qualquer momento.</p>
                <p className="text-sm font-bold pt-6">Base jurídica para o processamento</p>
                <p className="text-dark-gray text-sm pt-3">O Proprietário poderá processar os Dados Pessoais relacionados ao Usuário se uma das hipóteses a seguir se aplicar:</p>
                <ul className="list-disc pt-3 ml-5">
                    <li className="text-dark-gray text-sm">os Usuários tenham dado a sua anuência para uma ou mais finalidades específicas; Observação: De acordo com algumas legislações o Proprietário poderá ter a permissão para processar os Dados Pessoais ATÉ QUE O Usuário faça objeção a isto (“opt-out”), sem ter que se basear em anuência ou em quaisquer outras bases jurídicas a seguir. Isto contudo não se aplica sempre que o processamento de Dados Pessoais estiver sujeito à legislação europeia de proteção de dados;</li>
                    <li className="text-dark-gray text-sm">o fornecimento dos Dados for necessário para o cumprimento de um contrato com o Usuário e/ou quaisquer obrigações pré-contratuais do mesmo;</li>
                    <li className="text-dark-gray text-sm">o processamento for necessário para o cumprimento de uma obrigação jurídica à qual o Proprietário estiver sujeito;</li>
                    <li className="text-dark-gray text-sm">o processamento estiver relacionado a uma tarefa que for executada no interesse público ou no exercício de uma autorização oficial na qual o Proprietário estiver investido;</li>
                    <li className="text-dark-gray text-sm">o processamento for necessário para a finalidade de interesses legítimos perseguidos pelo Proprietário ou por um terceiro;</li>
                </ul>
                <p className="text-dark-gray text-sm pt-3">Em qualquer caso, o Proprietário colaborará de bom grado para esclarecer qual a base jurídica que se aplica ao processamento, e em especial se o fornecimento de Dados for um requisito obrigatório por força de lei ou contratual, ou uma exigência necessária para celebrar um contrato.</p>
                <p className="text-sm font-bold pt-6">Lugar</p>
                <p className="text-dark-gray text-sm pt-3">Os dados são processados nas sedes de operação dos Proprietários, e em quaisquer outros lugares onde as partes envolvidas com o processamento estiverem localizadas.</p>
                <p className="text-dark-gray text-sm pt-3">Dependendo da localização do Usuário as transferências de dados poderão envolver a transferência dos Dados do Usuário para outro país que não seja o seu. Para descobrirem mais sobre o local de processamento de tais Dados transferidos os Usuários poderão verificar a seção contendo os detalhes sobre o processamento de Dados Pessoais.</p>
                <p className="text-dark-gray text-sm pt-3">Os Usuários também possuem o direito de serem informados sobre a base jurídica das transferências de Dados para países de fora da União Europeia ou para quaisquer organizações internacionais regidas pelo direito internacional público ou formadas por dois ou mais países, tal como a ONU, e sobre as medidas de segurança tomadas pelo Proprietário para proteger os seus Dados.</p>
                <p className="text-dark-gray text-sm pt-3">Se ocorrerem quaisquer tais transferências os Usuários poderão descobrir mais a respeito verificando as seções pertinentes deste documento ou perguntando ao Proprietário utilizando as informações fornecidas na seção de contatos.</p>
                <p className="text-sm font-bold pt-6">Período de conservação</p>
                <p className="text-dark-gray text-sm pt-3">Os Dados Pessoais serão processados e armazenados pelo tempo que for necessário para as finalidades para as quais forem coletados.</p>
                <p className="text-dark-gray text-sm pt-3">Portanto:</p>
                <ul className="list-disc pt-3 ml-5">
                    <li className="text-dark-gray text-sm">Os Dados Pessoais coletados para as finalidades relacionadas com a execução de um contrato entre o Proprietário e o Usuário serão conservados até que tal contrato tenha sido completamente cumprido.</li>
                    <li className="text-dark-gray text-sm">Os Dados Pessoais coletados para as finalidades relacionadas com os legítimos interesses do Proprietário serão conservados pelo tempo que for necessário para cumprir tais finalidades. Os Usuários poderão obter informações específicas sobre os interesses legítimos perseguidos pelo Proprietário dentro das seções pertinentes deste documento ou entrando em contato com o Proprietário.</li>
                </ul>
                <p className="text-dark-gray text-sm pt-3">O Proprietário poderá ter a permissão de conservar os Dados Pessoais por um prazo maior sempre que o Usuário tiver dado a sua autorização para tal processamento, enquanto tal autorização não tiver sido retirada. Além disso, o Proprietário poderá ficar obrigado a conservar os Dados Pessoais por um prazo maior em todas as ocasiões em que estiver obrigado a fazê-lo para o cumprimento de uma obrigação jurídica ou em cumprimento de um mandado de uma autoridade.</p>
                <p className="text-dark-gray text-sm pt-3">Assim que o prazo de conservação vencer os Dados Pessoais serão apagados. Desta forma o direito de acessar, o direito de apagar, o direito de corrigir e o direito à portabilidade dos dados não poderão ter o seu cumprimento exigido após o vencimento do prazo de conservação.</p>

                <h1 className="text-lg mt-8">As finalidades do processamento</h1>
                <p className="text-dark-gray text-sm pt-3">Os Dados relativos ao Usuário são coletados para permitir que o Proprietário preste seu Serviço, cumpra suas obrigações legais, responda a solicitações de execução, proteja seus direitos e interesses (ou aqueles de seus Usuários ou terceiros), detecte qualquer atividade maliciosa ou fraudulenta, assim como: Estatísticas, Contatar o Usuário e Gerenciamento de contactos e envio de mensagens.</p>
                <p className="text-dark-gray text-sm pt-3">Para obter informações específicas sobre os Dados Pessoais utilizados para cada finalidade, o Usuário poderá consultar a seção "Informações detalhadas sobre o processamento de Dados Pessoais".</p>

                <h1 className="text-lg mt-8">Informações detalhadas sobre o processamento de Dados Pessoais</h1>
                <p className="text-dark-gray text-sm pt-3">Os Dados Pessoais são recolhidos para os seguintes fins e utilizando os seguintes serviços:</p>
                <ul className="list-disc pt-3 ml-5">
                    <li className="text-dark-gray text-sm">
                        <p className="text-sm font-bold">Formulário de contato (este Site)</p>
                        <p className="text-dark-gray text-sm pt-3">Ao preencher o formulário de contato com os seus Dados, os usuários autorizam este Aplicativo a usar esses detalhes para responder aos pedidos de informações, cotação ou qualquer outro tipo de pedido como indicado pelo título do formulário.</p>
                        <p className="text-dark-gray text-sm pt-3 pb-4">Dados Pessoais processados: e-mail; número de telefone.</p>
                    </li>
                    <li className="text-dark-gray text-sm">
                        <p className="text-sm font-bold">Gerenciamento de contactos e envio de mensagens</p>
                        <p className="text-dark-gray text-sm pt-3">Esses tipos de serviços permitem o gerenciamento da base de dados de contatos de e-mail, telefones ou outra informação de contato para comunicação com o Usuário.</p>
                        <p className="text-dark-gray text-sm pt-3">Estes serviços também podem ser usados para coletar dados referentes à data e o horário em que o Usuário visualizou o e-mail; e também quando o Usuário interagiu com o e-mail recebido, como por exemplo, quando o Usuário selecionou os links incluídos no e-mail.</p>
                        <p className="text-dark-gray text-sm pt-6 font-bold">Mailchimp (Intuit Inc.)</p>
                        <p className="text-dark-gray text-sm">Mailchimp é um gerenciamento de endereços de e-mail e envio de mensagem de serviço prestado por Intuit Inc.</p>
                        <p className="text-dark-gray text-sm">Dados Pessoais processados: e-mail.</p>
                        <p className="text-dark-gray text-sm pb-4">Lugar de processamento: EUA – <a href="https://www.intuit.com/privacy/statement/" target="_BLANK" rel="noreferrer nofollow">Política de Privacidade</a>.</p>
                    </li>
                    <li className="text-dark-gray text-sm">
                        <p className="text-sm font-bold">Estatísticas</p>
                        <p className="text-dark-gray text-sm pt-3">Os serviços contidos nesta seção habilitam o Proprietário a monitorar e analisar o tráfego da web e podem ser usados para rastrear o comportamento do Usuário.</p>
                        <p className="text-dark-gray text-sm pt-6 font-bold">Google Analytics (Google LLC)</p>
                        <p className="text-dark-gray text-sm">O Google Analytics é um serviço de análise da Internet fornecido pela Google LLC ("Google"). O Google utiliza os dados coletados para acompanhar e examinar o uso deste serviço (este Aplicativo) para preparar relatórios sobre atividades e compartilhá-los com outros serviços do Google.</p>
                        <p className="text-dark-gray text-sm">O Google pode usar os dados coletados para contextualizar e personalizar os anúncios de sua própria rede de publicidade.</p>
                        <p className="text-dark-gray text-sm">Dados Pessoais processados: Dados de uso; Rastreador.</p>
                        <p className="text-dark-gray text-sm">Lugar de processamento: EUA – <a href="https://policies.google.com/privacy" target="_BLANK" rel="noreferrer nofollow">Política de Privacidade – Opt Out</a>.</p>
                    </li>
                </ul>

                <h1 className="text-lg mt-8">Os direitos dos Usuários</h1>
                <p className="text-dark-gray text-sm pt-3">Os Usuários poderão exercer determinados direitos a respeito dos seus Dados processados pelo Proprietário.</p>
                <p className="text-dark-gray text-sm pt-3">Em especial, os Usuários possuem os direitos a fazer o seguinte:</p>
                <ul className="list-disc pt-3 ml-5">
                    <li className="text-dark-gray text-sm"><span className="font-bold">Retirar a sua anuência a qualquer momento.</span> Os Usuários possuem o direito de retirar a sua anuência nos casos em que tenham dado a sua anuência anteriormente para o processamento dos seus Dados Pessoais.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Objetar o processamento dos seus Dados.</span> Os Usuários possuem o direito de objetar o processamento dos seus Dados se o processamento for executado sobre outra base jurídica que não a anuência. São fornecidos detalhes adicionais na seção específica abaixo.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Acessar os seus Dados.</span> Os Usuários possuem o direito de saber se os seus Dados estão sendo tratados pelo Proprietário, obter revelações sobre determinados aspectos do tratamento e conseguir uma cópia dos Dados que estiverem sendo tratados.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Verificar e pedir retificação.</span> Os Usuários possuem o direito de verificar a exatidão dos seus Dados e de pedir que os mesmos sejam atualizados ou corrigidos.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Restringir o processamento dos seus Dados.</span> Os Usuários possuem o direito de, sob determinadas circunstâncias, restringir o processamento dos seus Dados para qualquer outra finalidade que não seja o armazenamento dos mesmos.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Ter os seus Dados Pessoais apagados ou retirados de outra maneira.</span> Os Usuários possuem o direito de, sob determinadas circunstâncias, obter a eliminação dos seus Dados do Proprietário.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Receber os seus Dados e ter os mesmos transferidos para outro controlador.</span> Os Usuários possuem o direito de receber os seus Dados em um formato estruturado, utilizado comumente e apto a ser lido por máquinas e, se for viável tecnicamente, fazer com que os mesmos sejam transmitidos para outro controlador sem nenhum empecilho. Esta determinação se aplica condicionada a que os Dados sejam processados por meios automatizados e que o processamento esteja baseado na anuência do Usuário, em um contrato do qual o Usuário seja uma das partes ou por obrigações pré-contratuais do mesmo.</li>
                    <li className="text-dark-gray text-sm"><span className="font-bold">Registrar uma reclamação.</span> Os Usuários possuem o direito de apresentar reclamação perante a sua autoridade de proteção de dados competente.</li>
                </ul>
                <p className="text-sm font-bold pt-6">Detalhes sobre o direito de objetar ao processamento</p>
                <p className="text-dark-gray text-sm pt-3">Nos casos em que os Dados Pessoais forem processados por um interesse público, no exercício de uma autorização oficial na qual o Proprietário estiver investido ou para finalidades dos interesses legítimos perseguidos pelo Proprietário, os Usuários poderão objetar tal processamento através do fornecimento de um motivo relacionado com a sua situação em especial para justificar a objeção.</p>
                <p className="text-dark-gray text-sm pt-3">Os Usuários devem saber, entretanto, que caso os seus Dados Pessoais sejam processados para finalidades de marketing direto eles podem objetar tal processamento a qualquer momento sem fornecer nenhuma justificativa. Os Usuários podem consultar as seções respectivas deste documento.</p>
                <p className="text-sm font-bold pt-6">Como exercer estes direitos</p>
                <p className="text-dark-gray text-sm pt-3">Quaisquer pedidos para exercer os direitos dos Usuários podem ser direcionados ao Proprietário através dos dados para contato fornecidos neste documento. Estes pedidos podem ser exercidos sem nenhum custo e serão atendidos pelo Proprietário com a maior brevidade possível e em todos os casos em prazo inferior a um mês.</p>

                <h1 className="text-lg mt-8">Informações adicionais sobre a coleta e processamento de Dados</h1>
                <p className="text-sm font-bold pt-6">Ação jurídica</p>
                <p className="text-dark-gray text-sm pt-3">Os Dados Pessoais dos Usuários podem ser utilizados para fins jurídicos pelo Proprietário em juízo ou nas etapas conducentes à possível ação jurídica decorrente de uso indevido deste Serviço (este Aplicativo) ou dos Serviços relacionados.</p>
                <p className="text-dark-gray text-sm pt-3">O Usuário declara estar ciente de que o Proprietário poderá ser obrigado a revelar os Dados Pessoais mediante solicitação das autoridades governamentais.</p>
                <p className="text-sm font-bold pt-6">Informações adicionais sobre os Dados Pessoais do Usuário</p>
                <p className="text-dark-gray text-sm pt-3">Além das informações contidas nesta política de privacidade, este Aplicativo poderá fornecer ao Usuário informações adicionais e contextuais sobre os serviços específicos ou a coleta e processamento de Dados Pessoais mediante solicitação.</p>
                <p className="text-sm font-bold pt-6">Logs do sistema e manutenção</p>
                <p className="text-dark-gray text-sm pt-3">Para fins de operação e manutenção, este Aplicativo e quaisquer serviços de terceiros poderão coletar arquivos que gravam a interação com este Aplicativo (logs do sistema) ou usar outros Dados Pessoais (tais como endereço IP) para esta finalidade.</p>
                <p className="text-sm font-bold pt-6">As informações não contidas nesta política</p>
                <p className="text-dark-gray text-sm pt-3">Mais detalhes sobre a coleta ou processamento de Dados Pessoais podem ser solicitados ao Proprietário, a qualquer momento. Favor ver as informações de contato no início deste documento.</p>
                <p className="text-sm font-bold pt-6">Como são tratados os pedidos de “Não me Rastreie”</p>
                <p className="text-dark-gray text-sm pt-3">Este Aplicativo não suporta pedidos de “Não Me Rastreie”.</p>
                <p className="text-dark-gray text-sm pt-3">Para determinar se qualquer um dos serviços de terceiros que utiliza honram solicitações de “Não Me Rastreie”, por favor leia as políticas de privacidade.</p>
                <p className="text-sm font-bold pt-6">Mudanças nesta política de privacidade</p>
                <p className="text-dark-gray text-sm pt-3">O Proprietário se reserva o direito de fazer alterações nesta política de privacidade a qualquer momento, através de notificação a seus Usuários nesta página e possivelmente dentro deste Serviço (este Aplicativo) e/ou – na medida em que for técnica e juridicamente viável – enviando um aviso para os Usuários através de quaisquer informações de contato disponíveis para o Proprietário. É altamente recomendável checar esta página regularmente, consultando a data da última modificação informada na parte inferior.</p>
                <p className="text-dark-gray text-sm pt-3">Caso as mudanças afetem as atividades de processamento realizadas com base na anuência do Usuário, o Proprietário coletará nova anuência do Usuário, onde for exigida.</p>


                <div className="bg-light-gray my-5 p-5 rounded">
                    <h1 className="text-lg">Definições e referências jurídicas</h1>
                    <p className="text-sm font-bold pt-6">Dados Pessoais (ou Dados)</p>
                    <p className="text-dark-gray text-sm pt-3">Quaisquer informações que diretamente, indiretamente ou em relação com outras informações – incluindo um número de identificação pessoal – permitam a identificação ou identificabilidade de uma pessoa física.</p>

                    <p className="text-sm font-bold pt-6">Dados de Uso</p>
                    <p className="text-dark-gray text-sm pt-3">As informações coletadas automaticamente através deste este Aplicativo (ou serviços de terceiros contratados neste Serviço (este Aplicativo)), que podem incluir: os endereços IP ou nomes de domínio dos computadores utilizados pelos Usuários que utilizam este Aplicativo, os endereços URI (Identificador Uniforme de Recurso), a data e hora do pedido, o método utilizado para submeter o pedido ao servidor, o tamanho do arquivo recebido em resposta, o código numérico que indica o status do servidor de resposta (resultado positivo, erro , etc.), o país de origem, as características do navegador e do sistema operacional utilizado pelo Usuário, os vários detalhes de tempo por visita (por exemplo, o tempo gasto em cada página dentro do aplicativo) e os detalhes sobre o caminho seguido dentro da aplicação, com especial referência à sequência de páginas visitadas e outros parâmetros sobre o sistema operacional do dispositivo e/ou ambiente de TI do Usuário.</p>

                    <p className="text-sm font-bold pt-6">Usuário</p>
                    <p className="text-dark-gray text-sm pt-3">A pessoa que usa este Aplicativo que, a menos que especificado diferentemente, coincida com o Titular dos Dados.</p>

                    <p className="text-sm font-bold pt-6">Titular dos Dados</p>
                    <p className="text-dark-gray text-sm pt-3">A pessoa física a quem os Dados Pessoais se referem.</p>

                    <p className="text-sm font-bold pt-6">Processador de Dados (ou supervisor de Dados)</p>
                    <p className="text-dark-gray text-sm pt-3">A pessoa física ou jurídica, administração pública, agência ou outro órgão que processe os Dados Pessoais em nome do Controlador conforme descrito nesta política de privacidade.</p>

                    <p className="text-sm font-bold pt-6">Controlador de Dados (ou Proprietário)</p>
                    <p className="text-dark-gray text-sm pt-3">A pessoa física ou jurídica, administração pública, agência ou outro órgão que, isoladamente ou em conjunto com outros determinar as finalidades e os meios de processamento dos Dados Pessoais, incluindo medidas de segurança relativas ao funcionamento e ao uso deste Serviço (este Aplicativo). O Controlador de Dados, a menos que seja especificado de outra forma, é o Proprietário deste Serviço (este Aplicativo).</p>

                    <p className="text-sm font-bold pt-6">Este Aplicativo</p>
                    <p className="text-dark-gray text-sm pt-3">O meio pelo qual os Dados Pessoais do Usuário são coletados e processados.</p>

                    <p className="text-sm font-bold pt-6">Serviço</p>
                    <p className="text-dark-gray text-sm pt-3">O serviço fornecido por este Aplicativo conforme descrito nos termos relativos (se disponíveis) e neste site/aplicativo.</p>

                    <p className="text-sm font-bold pt-6">União Europeia (ou UE)</p>
                    <p className="text-dark-gray text-sm pt-3">A menos que especificado diferentemente, todas as referências feitas neste documento à União Europeia incluem todos os atuais estados membros da União Europeia e do Espaço Econômico Europeu.</p>

                    <p className="text-sm font-bold pt-6">Cookie</p>
                    <p className="text-dark-gray text-sm pt-3">Cookies são Rastreadores compostos por pequenos conjuntos de dados armazenados no navegador do Usuário.</p>

                    <p className="text-sm font-bold pt-6">Rastreador</p>
                    <p className="text-dark-gray text-sm pt-3">O termo Rastreador indica qualquer tecnologia – como Cookies, identificadores únicos, web beacons, scripts embutidos, e-tags e fingerprinting – que permita o rastreamento de Usuários, por exemplo, acessando ou a armazenando informações no dispositivo do Usuário.</p>

                    <p className="text-sm font-bold pt-6">Informação jurídica</p>
                    <p className="text-dark-gray text-sm pt-3">Esta declaração de privacidade foi preparada com base em determinações de múltiplas legislações, inclusive os Arts. 13/14 do Regulamento (EU) 2016/679 (GDPR - Regulamento Geral de Proteção de Dados).</p>

                    <p className="text-dark-gray text-sm pt-3">Esta política de privacidade se refere somente a este Aplicativo, se não afirmado diferentemente neste documento.</p>
                </div>
                <p className="text-dark-gray text-sm">Última atualização: 7 de maio de 2022</p>
            </div>
        </Main>
    )
}

export default PoliticaDePrivacidade
